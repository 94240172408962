import { Select } from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import { SelectOption } from 'components/atoms/Select';
import { useRouter } from 'next/router';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { pinCursorToStartIfInputPlaceholder } from './utils/pinCursorToStartIfInputPlaceholder';

/**
 * Creates Unicode flag from a two-letter ISO country code.
 * https://stackoverflow.com/questions/24050671/how-to-put-japan-flag-character-in-a-string
 * @param  {string} country — A two-letter ISO country code (case-insensitive).
 * @return {string}
 */
export default function getCountryFlag(country) {
  return (
    getRegionalIndicatorSymbol(country[0]) +
    getRegionalIndicatorSymbol(country[1])
  );
}

/**
 * Converts a letter to a Regional Indicator Symbol.
 * @param  {string} letter
 * @return {string}
 */
function getRegionalIndicatorSymbol(letter) {
  return String.fromCodePoint(
    0x1f1e6 - 65 + letter.toUpperCase().charCodeAt(0),
  );
}

const phoneMaskPlaceholder = '(___) ___-____';
const masks = [
  {
    label: getCountryFlag('US'),

    mask: '+1 (000) 000-0000',
  },
  {
    label: getCountryFlag('ES'),

    mask: '+34 (000) 000-000',
  },
  {
    label: getCountryFlag('UA'),

    mask: '+38 (000) 000-0000',
  },
];

export const MobilePhoneMask = forwardRef(
  (props: InputBaseComponentProps, ref) => {
    const { onChange, ...other } = props;
    const maskedInputRef = useRef(null);
    const router = useRouter();
    const devMode = 'dev' in router.query;

    const [selectedMask, setSelectedMask] = useState(() => {
      const initMask = masks.find((m) =>
        other.value?.startsWith(m.mask.split(' ')[0]),
      );

      const defaultMask = devMode ? masks[2] : masks[0];
      return initMask ? initMask : defaultMask;
    });

    useImperativeHandle(ref, () => maskedInputRef.current);

    const handleMaskChange = (event) => {
      setSelectedMask(masks.find((m) => m.mask === event.target.value));
      onChange({
        target: {
          name: props.name,
          value: '',
        },
      } as any);
    };

    return (
      <>
        {!devMode ? (
          <SelectOption
            key={selectedMask.mask}
            value={selectedMask.mask}
            disableRipple
            sx={{
              '&&': {
                paddingRight: 0,
              },
              '&:hover': {
                backgroundColor: 'transparent',
                cursor: 'default',
              },
            }}
          >
            {selectedMask.label}
          </SelectOption>
        ) : (
          <Select onChange={handleMaskChange} value={selectedMask.mask}>
            {masks.map((option) => (
              <SelectOption key={option.mask} value={option.mask}>
                {option.label}
              </SelectOption>
            ))}
          </Select>
        )}

        <IMaskInput
          mask={selectedMask.mask}
          lazy={false}
          autofix
          overwrite
          unmask={false}
          onAccept={(val) => {
            onChange({
              target: {
                name: props.name,
                value: val,
              },
            } as any);
          }}
          inputMode='numeric'
          ref={maskedInputRef}
          onFocus={() => {
            pinCursorToStartIfInputPlaceholder(
              phoneMaskPlaceholder,
              maskedInputRef.current.element,
            );
          }}
          style={{
            paddingLeft: '8px',
          }}
          {...other}
        />
      </>
    );
  },
);

MobilePhoneMask.displayName = 'MobilePhoneMask';
