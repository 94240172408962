import { NextRouter } from 'next/router';
import { Permission, User } from 'shared/types/models';
import { E2E_CONSUMER_EMAIL_REGEX } from 'shared/utils/constants';
import { hasPermission } from 'shared/utils/functions';
import { roleUrls } from './const';

const SKIP_PHONE_CONFIRMATION = process.env.SKIP_PHONE_CONFIRMATION;

export const needSkipPhoneConfirmation = (email: string): boolean => {
  return SKIP_PHONE_CONFIRMATION && E2E_CONSUMER_EMAIL_REGEX.test(email);
};

const getRedirectUrl = (user: User): string => {
  if (hasPermission(user, Permission.dealer)) {
    return roleUrls[Permission.dealer];
  } else if (hasPermission(user, Permission.admin)) {
    return roleUrls[Permission.admin];
  } else if (hasPermission(user, Permission.user)) {
    return roleUrls[Permission.user];
  } else if (hasPermission(user, Permission.sales)) {
    return roleUrls[Permission.sales];
  }
};

export const afterLoginRedirect = (
  user: User,
  router: NextRouter,
  returnTo?: string,
) => {
  router.replace(
    (router.query.returnTo as string) || returnTo || getRedirectUrl(user),
  );
};
