import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { lightThemeMui5 } from 'theme';

const LightColorTimerText = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    textTransform: 'lowercase',
    fontSize: '0.8125rem',
  },
}));

export const BoldBody2IconTimerTextStyler: FC<string> = (timerText) => {
  return (
    <b>
      <Typography variant='body2' suppressHydrationWarning>
        <FontAwesomeIcon
          size='lg'
          icon={faClock}
          color={lightThemeMui5.palette.secondary.main}
        />
        &nbsp;
        {timerText}
      </Typography>
    </b>
  );
};

export const BoldH4TextStyler: FC<string> = (timerText) => {
  return (
    <b>
      <Typography
        variant='h4'
        display={'inline'}
        color={lightThemeMui5.palette.secondary.main}
        suppressHydrationWarning
      >
        {timerText}
      </Typography>
    </b>
  );
};

export const BoldBody1IconTimerTextStyler: FC<string> = (timerText) => {
  return (
    <b>
      <Typography
        variant='body1'
        suppressHydrationWarning
        sx={{ fontWeight: '700' }}
      >
        <FontAwesomeIcon
          size='lg'
          icon={faClock}
          color={lightThemeMui5.palette.secondary.main}
        />
        &nbsp;
        {timerText}
      </Typography>
    </b>
  );
};

export const BoldBody2IconTimerTextStylerBlue: FC<string> = (timerText) => {
  return (
    <b>
      <Typography variant='body2' suppressHydrationWarning>
        <FontAwesomeIcon
          size='lg'
          icon={faClock}
          color={lightThemeMui5.palette.primary.main}
        />
        &nbsp;
        {timerText}
      </Typography>
    </b>
  );
};

export const Body2TimerTextStyler: FC<string> = (timerText) => {
  return (
    <Typography variant='body2' suppressHydrationWarning>
      &nbsp;
      {timerText}
    </Typography>
  );
};

export const Body1TimerTextStyler: FC<string> = (timerText) => {
  return (
    <Typography variant='body1' noWrap suppressHydrationWarning>
      {timerText}
    </Typography>
  );
};

export const Body2LightTimerTextStyler: FC<string> = (timerText) => {
  return (
    <LightColorTimerText variant='body2' suppressHydrationWarning>
      {timerText}
    </LightColorTimerText>
  );
};
