export const pinCursorToStartIfInputPlaceholder = (
  emptyPlaceholder: string,
  inputElement: HTMLInputElement | undefined,
) => {
  if (inputElement && inputElement.value === emptyPlaceholder) {
    // Avoid setting cursor to the end on mobile devices while making "TAB"
    inputElement.selectionStart = 0;
    inputElement.selectionEnd = 0;
  }
};
