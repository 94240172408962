import Tooltip from '@mui/material/Tooltip';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { timeAtom } from 'recoil/atoms';
import { msToTime, msToTimeQuote, msToTimeShort } from 'utils/functions';
import {
  ONE_DAY,
  ONE_HOUR,
  ONE_MINUTE,
  ONE_SECOND,
} from '../../shared/utils/constants';

type TimerTextType = 'full' | 'full-quote' | 'short' | 'minsec';

type TimerProps = {
  endTime?: string | number;
  startTime?: string;
  textTail?: string;
  textPrefix?: string;
  textType?: TimerTextType;
  textCompleted?: string;
  children: FC<string>;
  tooltip?: string;
  onTimeOut?: () => void;
};

/**
 * Usage note: Pass any custom `TimerTextStyler` function as a children to style component!
 *
 * Timer example:
 *
 * ```ts
 * <Timer endTime={endTime} textCompleted='Expired'>
 *   {SomeComponentTimerTextStyler}
 * </Timer>
 * ```
 *
 * Styler example:
 * ```ts
 * const SomeComponentTimerTextStyler: FC<string> = (timerText) => {
 *  return (
 *    <Typography variant='body2'>
 *      <FontAwesomeIcon
 *        size='lg'
 *        icon={faClock}
 *        color={lightTheme.palette.secondary.main}
 *      />
 *      &nbsp;
 *      {timerText}
 *    </Typography>
 *  );
 * };
 * ```
 */
export const Timer: FC<TimerProps> = ({
  endTime,
  startTime,
  textTail = 'left',
  textPrefix = 'in',
  textType = 'full',
  textCompleted = 'Completed',
  children,
  tooltip,
  onTimeOut = () => {},
}) => {
  const getNewTimerTimeToTarget = useCallback(
    (time) => {
      if (endTime) return new Date(endTime).getTime() - time;
      if (startTime) return Math.abs(time - new Date(startTime).getTime());
      return 0;
    },
    [endTime, startTime],
  );

  const time = useRecoilValue(timeAtom);
  const timeToTarget = useRef(getNewTimerTimeToTarget(time));
  const isFutureTime = new Date(startTime).getTime() > time;

  if (!isFutureTime) {
    textPrefix = '';
  } else {
    textTail = '';
  }

  useEffect(() => {
    timeToTarget.current = getNewTimerTimeToTarget(time);
  }, [getNewTimerTimeToTarget, time]);

  const getTimerText = useCallback((): string => {
    if (timeToTarget.current <= 0) return textCompleted;

    if (textType === 'full') {
      return `${textPrefix} ${msToTime(timeToTarget.current)} ${textTail}`;
    }

    if (textType === 'full-quote') {
      return `${textPrefix} ${msToTimeQuote(timeToTarget.current)} ${textTail}`;
    }

    if (textType === 'short') {
      if (timeToTarget.current >= ONE_DAY) {
        const days = Math.floor(timeToTarget.current / ONE_DAY);

        return `${textPrefix} ${days} ${
          days === 1 ? 'day' : 'days'
        } ${textTail}`;
      }

      if (timeToTarget.current >= ONE_HOUR) {
        const hours = Math.floor(timeToTarget.current / ONE_HOUR);

        return `${textPrefix} ${hours} ${
          hours === 1 ? 'hour' : 'hours'
        } ${textTail}`;
      }

      if (timeToTarget.current >= ONE_MINUTE) {
        const minutes = Math.floor(timeToTarget.current / ONE_MINUTE);

        return `${textPrefix} ${minutes} ${
          minutes === 1 ? 'minute' : 'minutes'
        } ${textTail}`;
      }

      if (timeToTarget.current >= ONE_SECOND) {
        const seconds = Math.floor(timeToTarget.current / ONE_SECOND);

        return `${textPrefix} ${seconds} ${
          seconds === 1 ? 'second' : 'seconds'
        } ${textTail}`;
      }
    }

    if (textType === 'minsec') {
      return `${textPrefix} ${msToTimeShort(timeToTarget.current)} ${textTail}`;
    }

    return '';
  }, [textCompleted, textTail, textType, textPrefix]);

  const timerText = getTimerText();
  if (timeToTarget.current <= 0) {
    onTimeOut();
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <span>{children(timerText)}</span>
      </Tooltip>
    );
  }

  return children(timerText);
};
