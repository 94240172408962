import Alert from '@mui/material/Alert';
import FieldText from 'containers/profile/FieldText';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const LoginTextField = styled(FieldText)(({ theme }) => ({
  '&.MuiTextField-root': {
    margin: theme.spacing(5, 0, 2),
  },
}));

export const LoginAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const Title = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    marginBottom: theme.spacing(3),
  },
}));

export const DialogActionsContainer = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}));
